import React from "react";
import Navbar from "../components/Navbar/Navbar";
import Footer from "../components/Footer/Footer";

import classes from "./styles/FAQ.module.css";

import {faqContent} from "../content/faqContent";
import Container from "../components/Container/Container";

const FAQ = () => {
  let faq = faqContent.map((qaPair, index) => {
    return (
      <div className={classes.pair} key={index}>
        <p className={classes.question}>Q: {qaPair.question}</p>
        <p className={classes.answer}>A: {qaPair.answer}</p>
      </div>
    );
  });

  return (
    <div className={classes.FAQ}>
      <Navbar />
      <div className={classes.content}>
        <Container>
          <h1>Frequently Asked Questions</h1>
          <div className={classes.faq}>
            {faq}
          </div>
        </Container>
      </div>
      <Footer />
    </div>
  );
}

export default FAQ;