export const faqContent = [
  {
    question: "Is there free parking?",
    answer: "Yes there is free parking available and off street parking.",
  },
  {
    question: "Is the building wheelchair accessible?",
    answer: "Yes. There is wheelchair accessibility with handy disability parking by the entrance.",
  },
  {
    question: "Do I need a referral to be seen by Podiatrist?",
    answer: "No, you do not. Give us a call and we can book you for an appointment.",
  },
  {
    question: "How long are your appointments?",
    answer: "Our appointments are all 30mins.",
  },
  {
    question: "Do you work on Saturdays?",
    answer: "Yes, we do work on some Saturdays just call in to book!",
  }
];